declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    isExpanded: boolean;
    data: any;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'tachometer', isExpanded: false, data: [] },
    { path: '/users', title: 'Users', icon: 'users', isExpanded: false, data: ['Push', 'Two Factor', 'Posts', 'Comments', 'Update Database'] },
    { path: '/letters', title: 'Letters', icon: 'envelope', isExpanded: false, data: ['Add', 'Edit'] },
    { path: '/group', title: 'Group', icon: 'users', isExpanded: false, data: ['Add', 'Edit', 'Detail', 'Posts', 'Comments'] },
    { path: '/news', title: 'News', icon: 'newspaper-o', isExpanded: false, data: ['Edit'] },
    { path: '/appreciation', title: 'Appreciation', icon: 'trophy', isExpanded: false, data: ['Edit'] },
    { path: '/annoucement', title: 'Annoucement', icon: 'bullhorn', isExpanded: false, data: ['Edit'] },
    { path: '/service-awards', title: 'Service Awards', icon: 'certificate', isExpanded: false, data: ['Edit'] },
    { path: '/points', title: 'Points', icon: 'gift', isExpanded: false, data: ['Edit'] },

    {
        path: '', title: 'Poll', icon: 'bar-chart', isExpanded: false, data: [
            { path: '/poll/list', title: 'Polls List', icon: 'dot-circle-o', data: ['Export', 'Action', 'Add'] },
            { path: '/poll/access', title: 'Poll Access', icon: 'dot-circle-o', data: ['Edit'] },
        ]
    },
    {
        path: '', title: 'Event', icon: 'calendar', isExpanded: false, data: [
            { path: '/event/list', title: 'Event List', icon: 'dot-circle-o', data: ['Detail'] },
            { path: '/event/access', title: 'Event Access', icon: 'dot-circle-o', data: ['Edit'] },
        ]
    },
    {
        path: '', title: 'Settings', icon: 'list', isExpanded: false, data: [
            { path: '/setting/profile-fields', title: 'Manage Profile Fields', icon: 'tasks', data: ['Edit'] },
        ]
    },
    {
        path: '', title: 'Challenge', icon: 'trophy', isExpanded: false, data: [
            { path: '/challenge/list', title: 'Challenge List', icon: 'dot-circle-o', data: ['Export', 'Action', 'Add'] },
            { path: '/challenge/access', title: 'Challenge Access', icon: 'dot-circle-o', data: ['Edit'] },
            { path: '/challenge/category', title: 'Challenge Category', icon: 'dot-circle-o', data: ['Add', 'Edit'] },
        ]
    },
    // {
    //     path: '', title: 'Activity', icon: 'trophy', isExpanded: false, data: [
    //         { path: '/activity/list', title: 'Activity List', icon: 'dot-circle-o', data: ['Export', 'Action', 'Add'] },
    //         { path: '/activity/access', title: 'Activity Access', icon: 'dot-circle-o', data: ['Edit'] }
    //     ]
    // },
    { path: '/health-kit', title: 'Health And Wellness', isExpanded: false, icon: 'medkit', data: [] },
    { path: '/admin-defaults', title: 'Admin Defaults', isExpanded: false, icon: 'medkit', data: ['Edit'] },
    { path: '/access', title: 'Access Control', isExpanded: false, icon: 'universal-access', data: ['Add', 'Edit'] }

];
